import React, { Component, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // DialogTitle,
  IconButton,

  spanField,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { Const } from "./Const";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import ScrollToBottom from "react-scroll-to-bottom";
var classNames = require("classnames");

const styles1 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});
// const DialogTitle = withStyles(styles1)((props) => {

//   const { children, classes, onClose, ...other ,close} = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
   
//         <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.close}>
//           <CloseIcon />
//         </IconButton>
   
//     </MuiDialogTitle>
//   );
// });

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      iskey: false,
      height: 0,
    };
  }

  handleMsg = (e) => {
    this.setState({ msg: e.target.value });
  };

  sendLocal() {
    this.props.send(this.state.msg);
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.send(this.state.msg);
                  this.setState({ msg: "" });
    }
  }
  render() {
    const { msg, iskey } = this.state;

    const messages = this.props.messages.map((m) => {
      let isme = false;
      let ishost = false;
      let isstaff = false;
      if (m.id == this.props.userid && m.ishost == "0") {
        isme = true;
      }
      if (m.ishost == "1") {
        ishost = true;
      }
      if (m.isstaff == "1") {
        isstaff = true;
      }

      var Classes = classNames({
        msg: true,
        me: isme,
      });
      var Classes1 = classNames({
        username: true,
        host: ishost,
      });
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <span>{'\u2B24'} </span> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: isme ? "flex-end" : "flex-start",
              paddingRight: 10,
            }}
          >
            <span className={Classes1}>
              {m.username}
              {ishost ? "(Host)" : ""}
              {isstaff ? "(Staff)" : ""}
            </span>
            <span className={Classes}>{m.msg}</span>
            <span style={styles.time}>{m.time}</span>
          </div>
        </div>
      );
    });

    return (
      <Dialog
        open={this.props.show}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
       <MuiDialogTitle style={{display:"flex",alignItems:"center",justifyContent:'space-between'}} disableTypography >
      <Typography variant="h6">Chat</Typography>
   
        <IconButton  className="closeicon" aria-label="close" onClick={this.props.close}>
          <CloseIcon />
        </IconButton>
   
    </MuiDialogTitle>
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
          <DialogContentText>
            <ScrollToBottom className="msgcon">{messages}</ScrollToBottom>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <input
          onKeyDown={this._handleKeyDown}
            fullWidth
          id="outlined-basic"  variant="outlined"
            className="inputtextchat"
            value={msg}
            onChange={this.handleMsg}
            id="standard-basic"
            placeholder="Type Message to Send"
          />
       
        

          <IconButton className="sendicon"
                 onClick={() => {
                  this.props.send(msg);
                  this.setState({ msg: "" });
                }}
                 
                  // style={styles.menuButton}
           
                  aria-label="send"
                >
                  <SendIcon />
                </IconButton>


        </DialogActions>
      </Dialog>

      // <Dialog
      // dialogStyle={iskey?this.styleios():''}
      //   width="90%"
      //   visible={this.props.show}
      //   onTouchOutside={() => {
      //     // this.props.setIschatShow();
      //   }}
      //   dialogTitle={
      //     <div style={{backgroundColor:'#B41487',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',padding:10}}>
      //       <span style={{fontSize:20,color:'white'}}>Chat</span>
      //       <TouchableOpacity   onPress={() => {
      //           this.props.setIschatShow();
      //         }}>
      //       <Icon style={{ color: "white" }} name="close" />
      //       </TouchableOpacity>
      //     </div>

      //   }
      // >
      //   <DialogContent
      //     style={{
      //       display: "flex",
      //       flexDirection: "column",
      //       alignContent: "space-between",
      //       height: screenHeight * 0.5,
      //     }}
      //   >
      //     <Scrolldiv
      //       style={{ flex: 1 }}
      //       ref={(ref) => (this.scrolldiv = ref)}
      //       onContentSizeChange={(contentWidth, contentHeight) => {
      //         this.scrolldiv.scrollToEnd({ animated: true });
      //       }}
      //     >
      //       {messages}
      //     </Scrolldiv>
      //     <div>
      //       <div
      //         style={{
      //           display: "flex",
      //           flexDirection: "row",
      //           justifyContent: "space-between",
      //           alignItems: "center",
      //         }}
      //       >
      //         <spanInput

      //           style={{
      //             height: 40,
      //             flex: 1,
      //             paddingLeft: 20,
      //             marginRight: 20,
      //             borderColor: "#B41487",
      //             borderWidth: 1,
      //             borderRadius: 50,
      //             backgroundColor: "#B4148710",
      //           }}
      //           value={msg}
      //           placeholder="Type message to send"
      //           onChangespan={(msg) => this.setState({ msg })}
      //         />

      //         <TouchableOpacity
      //           onPress={() => {
      //             this.props.send(msg);
      //             this.setState({ msg: "" });
      //           }}
      //         >
      //           <Icon style={{ color: "#B41487", fontSize: 40 }} name="send" />
      //         </TouchableOpacity>

      //       </div>

      //     </div>
      //   </DialogContent>
      // </Dialog>
    );
  }
}

const styles = {
  pophead: { color: "white", fontSize: 30 },
  username: {
    fontWeight: "700",
  },
  msg: {
    backgroundColor: "#ddd",
    paddingLeft: 10,
    paddingBottom: 5,
    paddingTop: 5,
    paddingRight: 10,
    borderRadius: 15,
  },
  time: {
    fontSize: 10,
    opacity: 0.9,
  },
  me: {
    backgroundColor: "#B4148790",
    color: "white",
  },
  host: {
    color: "#B41487",
  },
  title: {
    // backgroundColor:'green'    ,
    width: "30%",
    fontSize: 25,
  },
  data: {
    // backgroundColor:'red',
    width: "70%",
    fontSize: 25,
  },
  row_: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 10,
    // width:'100%'
  },
  header: {
    backgroundColor: "#B41487",
    color: "white",
  },
  buttonspan: {
    spanAlign: "center",
    color: "white",
    fontWeight: "700",
    fontSize: 20,
  },
  button: {
    marginTop: 20,
    backgroundColor: "#B41487",
    // borderColorLwhite:'white'
  },
  buttonSec: {
    marginTop: 20,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#B41487",

    elevation: 0,
  },
  buttonspan: {
    spanAlign: "center",
    color: "#B41487",
    fontWeight: "700",
    fontSize: 20,
  },
};
export { Chat };
