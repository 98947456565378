import Axios from "axios";
import React, { Component } from "react";
import authHeader from "../services/auth-header";
import authService from "../services/auth.service";
import { Const } from "./Const";
import Button from "@material-ui/core/Button";
import leftbanner from "../assets/img/left.png";
import rightbanner from "../assets/img/right.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import button1 from "../assets/img/weekly-calendar.png";
import button2 from "../assets/img/male-reporter.png";
import button3 from "../assets/img/phone-receiver.png";
import button4 from "../assets/img/online.png";

import { Card } from "./Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DialogContentText, DialogTitle, Typography } from "@material-ui/core";


import UIfx from "uifx";
import callBingoAudio from "../assets/sounds/callbingo.mp3";
import dingAudio from "../assets/sounds/ding.mp3";


import { WinCardCon } from "./WinCardCon";
import { VideoCon } from "./VideoCon";
import { ImageCon } from "./ImageCon";
import { Chat } from "./Chat";
import ReactPlayer from "react-player";
import { PatternCon } from "./PatternCon";

const callBingo = new UIfx(callBingoAudio, {
  volume: 0.4, // number between 0.0 ~ 1.0
  throttleMs: 100,
});


const ding = new UIfx(dingAudio, {
  volume: 0.4, // number between 0.0 ~ 1.0
  throttleMs: 100,
});



var classNames = require("classnames");
const io = require("socket.io-client");
const bingocardback = "gray";
const no75 = new Array();
for (let i = 1; i <= 75; i++) {
  no75.push(i);
}

class Main extends Component {
  socket = io(Const.SOCKET_ENDPOINT, {
    transports: ["websocket"],
  });
  constructor(props) {
    super(props);
    this.state = {
      sendMsgModal: false,
      carderror: false,
      channel: this.props.channel,
      loading: true,
      isConnected: false,
      drawnumber: "",
      lastdrawn: "",
      numbersdrawn: "",
      showvalidatemodal: false,
      // for video playing
      playVideo: false,
      videourl: "",
      playpause: false,

      winplayercard: "",
      winplayername: "",
      //for imageshow
      imageurl: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      patternurl: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",

      showImage: false,
      showPattern: false,
      //for card numbers
      card: [],
      allnumbers: [],

      visible: false,
      test: "sajith",

      //show alert
      alert: "",
      alertstatus: false,

      // for show all

      isshowall: false,

      //
      user: [],
      playerinfostatus: false,
      gamecolor: "#1f487c",
      gameno: 0,

      imageheight: 0,

      onlinecount: 0,
      calldis: false,
      winCard: false,
      pattern: [],
      gamenames: [
        "Regular",
        "Regular",
        "Regular",
        "Four Corners",
        "C Pattern",
        "Regular",
        "Regular",
        "Regular",
        "Plus Sign",
        "Blackout",
      ],

      imageHash: Date.now(),
      //chat
      messages: [],
      isChatShow: false,
      gameback: "",
      activemc: "",

      radiolink: this.props.radio,
      opacity: 0,

      answer: "",
      mute: this.props.mute,

      // radiomute: this.props.radiomute,
      radiopaused:this.props.radiomute
    };
    this.showCard = this.showCard.bind(this);
  }


  toggleradio=(val)=>{
    // alert(val)
  this.setState({  radiopaused:val})
  }
  async componentWillUnmount() {
   
    // await radiostream.unloadAsync();

    // this.props.onRef(undefined);
    this.socket.emit("leave_channel", {
      channel: this.state.channel,
    });
    this.socket.disconnect("data");
    this.setState({channel:''})
    // AppState.removeEventListener("change", this._handleAppStateChange);
  }

  getCard = (number) => {
    if (this.state.user.isstaff == 1) {
      return;
    }
    var d = new Date();
    this.setState({ loading: true, carderror: false });

    Axios({
      method: "GET",
      url:
        "https://tv.bingo:49156/api/player/get-card?num=" +
        number +
        "&user=" +
        authService.getCurrentUser().id +
        "&hostid=" +
        this.props.hostid +
        "&time=" +
        d.toLocaleDateString() +
        " " +
        d.toLocaleTimeString(),
      headers: authHeader(),
    })
      .then((response) => {
        this.setState({
          card: response.data.card,
          loading: false,
        });
        console.log(response.data.card);
      })
      .catch((error) => {
        this.setState({
          carderror: true,
        });
        console.log(error);
      });
  };
  middle = function (params) {
    return {
      backgroundColor: this.state.gamecolor,

      height: 64,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    };
  };

  componentDidMount() {
    this.props.onRef(this);
    this.socket.on("connect", () => {
      this.setState({ isConnected: true });
      this.socket.emit("join_channel", {
        channel: this.state.channel,
        device: "app",
        user_id: authService.getCurrentUser().id,
        isstaff: authService.getCurrentUser().isstaff,
        name:
          authService.getCurrentUser().firstname +
          " " +
          authService.getCurrentUser().lastname,
        city: authService.getCurrentUser().city,
      });
    });

    this.socket.on("disconnect", () => {
      this.setState({ isConnected: false });
    });

    this.socket.on("holdcardsfromstaff_", (data) => {
      //  alert(authService.getCurrentUser().isstaff)
      if (authService.getCurrentUser().isstaff == "0") {
        this.setState({ alertstatus: true, alert: data.message });
      }
    });

    this.socket.on("logoff_", (data) => {
      //here no need to join channel
      if (authService.getCurrentUser().id == data.user_id) {
        alert("you are going to logoff ");
      	authService.logout();
    // this.setState({ currentUser: undefined });
    window.location.reload()
      }
    });
    this.socket.on("video_", (data) => {
      this.setState({ videourl: data.url });
      this.play(data.status);
      // alert(data.status)
      if (data.mute == 1) {
        if (data.status == 1) {
          this.setState({radiopaused:false})
          // radiostream.unloadAsync();
        } else if (data.status == 3) {
          // this.loadRMute();
          // alert(this.props.radiomute)
          if (this.props.radiomute) {
            this.setState({radiopaused:true})
          }
        }
      }

      // console.log(data);
    });
    this.socket.on("presentationon_", (data) => {
   
      this.setState({ showImage: data.status, imageurl: data.url });
    });
    this.socket.on("pattern_", (data) => {
      console.log("------------pattern-----------------------");
      // alert('sd')
      this.setState({ showPattern: data.status, patternurl: data.url });
    });
    this.socket.on("sendimage_", (data) => {
      // console.log(data);
      this.setState({ imageurl: data.url, showImage: true });
    });
    this.socket.on("sendmsgtoapp_", (data) => {
      // alert( data.message)
      // console.log(data);
      // if (authService.getCurrentUser().isstaff == "0") {
      this.setState({ alertstatus: true, alert: data.message });
      // }
    });
    this.socket.on("numbers_", (data) => {
      console.log(data);

      if (this.state.drawnumber != data.pre_drawnumber) {

        this.Playding();
      }
      this.setState({
        drawnumber: data.pre_drawnumber,
        numbersdrawn: data.numbersdrawn,
        lastdrawn: data.pre_lastdrawnumber,

        allnumbers: data.allnumbers,
        test: "sajith sajith",
      });
    });
    let this_ = this;
    this.socket.on("changegameno_", (data) => {
    console.log(data)
      // ger card only if game no changes
      if (this.state.gameno != data.gameno) {
        this_.getCard(data.gameno);
      }
      this.setState({
       
        gameno: data.gameno,
        gameback: data.gameback,
      });
      if(data.color!=""){
        this.setState({
          gamecolor: data.color,
       
        });
      }
      else{
        this.setState({
         gamecolor: "#1f487c"
       
        });
      }
    });
    this.socket.on("onlinecount_", (data) => {
      console.log(data);
      this.setState({ onlinecount: data.count });
    });

    this.socket.on("winner_", (data) => {
      // if(authService.getCurrentUser().isstaff==0){
      this.setState({
        alertstatus: false,
        winCard: true,
        pattern: data.pattern,
        videourl: data.url,
        showvalidatemodal: false,
      });
      setTimeout(this.setCardwinState, 5000);
      // }
    });

    this.socket.on("nowinner_", (data) => {
      // if(authService.getCurrentUser().isstaff==0){
      this.setState({
        err: data.msg,
      });
    });

    this.socket.on("sendchat_", (data) => {
      // console.log(data)
      // this.setState({ winCard: true,pattern:data.pattern , videourl: data.url});
      // setTimeout(this.setCardwinState, 5000);
      var arr = [];
      arr = this.state.messages;
      arr.push(data);
      this.setState({ messages: arr });
      // console.log( this.state.messages)
    });


    if (!this.props.radiomute) {
      this.setState({radiopaused:true})
    }

  }

  setCardwinState=() =>{
    this.setState({ winCard: false });

    this.play(1);
  }
  async Playding() {
    if (this.props.mute ) {
      ding.play()
    }
  }
  play(param) {
    this.setState({ alertstatus: false });
    if (param == 1) {
      if (this.state.playVideo) {
        this.setState({ playpause: true });
        // alert("here");
      } else {
        // alert("there");
        this.setState({ playVideo: true, playpause: true });
      }
    } else if (param == 2) {
      this.setState({ playpause: false });
    } else if (param == 3) {
      this.setState({ playVideo: false });
    }
  }
  setPlayVideo=(playVideo)=> {
    // alert(playVideo)
    if (playVideo == false) {
      if (this.props.radiomute) {
       this.setState({radiopaused:true})
      }
      // radiostream.setIsMutedAsync(false);;
    }

    this.setState({
      playVideo: playVideo,
      playpause: playVideo,
    });
  }
  showVideoComponent() {
    if (this.state.playVideo) {
      return (
        <VideoCon
          setPlayVideo={this.setPlayVideo}
          url={this.state.videourl}
          playpause={this.state.playpause}
        />
      );
    } else {
      return;
    }
  }

  showCard() {
    //
    let color = "green";

    // if (this.state.user.isstaff) {
    // return (
    //   <View
    //     style={{
    //       padding: 10,
    //       flex: 1,
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //       height: "100%",
    //     }}
    //   >
    //     <SendMsg
    //       chanel={this.state.channel}
    //       ref={(ref) => {
    //         this.myModal = ref;
    //       }}
    //     />

    //     <ImageUp channel={this.state.channel} />
    //   </View>
    // );
    // } else {
    if (this.state.loading) {
      return <div style={styles.container}>{this.showTryAgain()}</div>;
    } else {
      return (
        <Card
          mute={this.props.mute}
          activemc={this.props.hexcolor}
          test={this.state.test}
          allnumbers={this.state.allnumbers}
          bingocardback={bingocardback}
          card={this.state.card}
          gameno={this.state.gameno}
        />
      );
    }
    // }
  }
  showTryAgain() {
    if (this.state.carderror) {
      // return (
      //   <View
      //     style={{
      //       position: "relative",
      //       display: "flex",
      //       justifyContent: "center",
      //       alignItems: "center",
      //       margin: 40,
      //     }}
      //   >
      //     <TouchableOpacity
      //       style={{
      //         backgroundColor: "#ddd",
      //         position: "absolute",
      //         paddingHorizontal: 15,
      //         paddingVertical: 5,
      //         margin: 5,
      //       }}
      //       onPress={() => {
      //         this.getCard();
      //       }}
      //     >
      //       <Text style={{ fontSize: 18 }}>Retry</Text>
      //     </TouchableOpacity>
      //   </View>
      // );
    } else {
      return <CircularProgress style={{color:this.state.gamecolor}} />;
    }
  }
   callBingo=()=> {
    // alert(this.state.answer)
    if (this.props.mute) {
     callBingo.play()
    }

    var d = new Date();

    this.socket.emit("callbingo", {
      channel: this.state.channel,
      user:authService.getCurrentUser(),
      cardnumber: this.state.card[12].value,
      time: d.toLocaleDateString() + " " + d.toLocaleTimeString(),
      answer: this.state.answer,
    });

    this.setState({ calldis: true });
    setTimeout(this.setCallDis, 10000);
  }
  setCallDis=()=> {
    this.setState({ calldis: false });
  }
  showPlayerInfo = () => {
    if (this.state.playerinfostatus) {
      return (
        <Dialog
          open={true}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Player Info</DialogTitle>
          <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
            <DialogContentText style={{ width: Const.DIALOG_WIDTH }} >
              <table className="tbl-p-info">
                <tr>
                  <td width="30%">
                    <h3>Name</h3>
                  </td>
                  <td>
                  <h3>{ authService.getCurrentUser().firstname + " " + authService.getCurrentUser().lastname}</h3>
                  </td>
                </tr>

                <tr>
                  <td width="30%">
                    <h3>Email</h3>
                  </td>
                  <td>
                  <h3>{ authService.getCurrentUser().email}</h3>
                  </td>
                </tr>



                <tr>
                  <td width="30%">
                    <h3>Phone</h3>
                  </td>
                  <td>
                  <h3>{ authService.getCurrentUser().phone}</h3>
                  </td>
                </tr>


                <tr>
                  <td width="30%">
                    <h3>Address</h3>
                  </td>
                  <td>
                  <h3>{    authService.getCurrentUser().street +
            ", " +
            authService.getCurrentUser().city +
            ", " +
            authService.getCurrentUser().state +
            ", " +
            authService.getCurrentUser().zip}</h3>
                  </td>
                </tr>


              </table>
            </DialogContentText>
            {/* <TextField
              fullWidth
              className="input"
              value={this.state.password}
              onChange={this.handlePin}
              type="password"
              id="standard-basic"
              label="Please Enter Password to Join"
            />
            <div>{this.state.err}</div> */}
          </DialogContent>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={() => {
                this.setState({ playerinfostatus: false });
              }}
              style={{ width: "100%" }}
            >
              Hide
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  showAlert = () => {
    if (this.state.alertstatus) {
      return (
        <Dialog
          open={true}
        
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Alert</DialogTitle>
          <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
            <DialogContentText style={{ width: Const.DIALOG_WIDTH }} >
          <h3>  {this.state.alert}</h3>
          </DialogContentText>
          
          </DialogContent>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={() => {
                this.setState({ alertstatus: false });
              }}
              style={{ width: "100%" }}
            >
              Hide
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };
  showAll = () => {
    const showallcontent = no75.map((ele, index) => {
      let st = null;
      if (this.state.allnumbers.includes(ele)) {
        st = styles.highlightTile;

        var activeclasses = classNames({
          tilesa: true,
          "highlight-tile": true,
        });
      } else {
        var activeclasses = classNames({
          tilesa: true,
          "highlight-tile": false,
        });
      }
      return (
        <div
          style={{
            height: (Const.SCREEN_HEIGHT - 64 - 64) / 16,
            borderColor: bingocardback,
          }}
          className={activeclasses}
          key={"tile" + index}
        >
          <span style={{ fontSize: 25 }}>{ele}</span>
        </div>
      );
    });
    if (this.state.isshowall) {
      return (
        <div
          style={{
            backgroundColor: bingocardback,
            position: "absolute",
            width: Const.SCREEN_WIDTH,
            height: Const.SCREEN_HEIGHT - 64 - 64,
            zIndex: 1100000,
          }}
        >
          <div
            onClick={() => {
              this.setState({ isshowall: false });
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                height: (Const.SCREEN_HEIGHT - 64 - 64) / 16,
              }}
            >
              <div style={styles.tileTitle}>
                <span style={styles.tileTitletext}>B</span>
              </div>
              <div style={styles.tileTitle}>
                <span style={styles.tileTitletext}>I</span>
              </div>
              <div style={styles.tileTitle}>
                <span style={styles.tileTitletext}>N</span>
              </div>
              <div style={styles.tileTitle}>
                <span style={styles.tileTitletext}>G</span>
              </div>
              <div style={styles.tileTitle}>
                <span style={styles.tileTitletext}>O</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                height:
                  Const.SCREEN_HEIGHT -
                  64 -
                  64 -
                  (Const.SCREEN_HEIGHT - 64 - 64) / 16,
              }}
            >
              {showallcontent}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#B41487",
                height: 64,
              }}
            >
              <span style={{ color: "white", fontSize: 20 }}>
                Touch anywhere to hide this window
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  showImageComponent() {
    if (this.state.showImage) {
     
      return <ImageCon url={this.state.imageurl} />;
    } else {
      return;
    }
  }
  showWinCard() {
    if (this.state.winCard) {
      return (
        <WinCardCon
          pattern={this.state.pattern}
          // setPlayVideo={this.setPlayVideo}
          // url={this.state.videourl}
          // playpause={this.state.playpause}
        />
      );
    } else {
      return;
    }
  }
  send=(msg)=> {
    let time = new Date();
    if (msg != "") {
      this.socket.emit("sendchat", {
        channel: this.state.channel,
        username:
         authService.getCurrentUser().firstname +
          "-" +
          authService.getCurrentUser().city +
          "," +
          authService.getCurrentUser().state,
        msg: msg,
        ishost: 0,
        isstaff:  authService.getCurrentUser().isstaff,
        id:  authService.getCurrentUser().id,
        time: time,
      });
    }

    // console.log(msg)
  }
  setIschatShow=()=>{
    this.setState({
      isChatShow: false,
    });
  }
  showChat=() =>{
    this.setState({
      isChatShow: true,
    });
  }
  chatclose=()=>{
    this.setState({
      isChatShow: false,
    });
  }
  BoolTOstring(bool){
    if(bool){
      return "1"
    }
    return "0"
  }
  showPatternComponent() {
    if (this.state.showPattern) {
      return <PatternCon url={this.state.patternurl} />;
    } else {
      // alert('')
      return;
    }
  }
  render() {
    const {
      numbersdrawn,
      lastdrawn,
      drawnumber,
      gameno,
      gamenames,
      imageHash,
      gameback,
      mute
    } = this.state;
    return (
      <div
        style={{
          backgroundColor: "white",
          height: window.innerHeight - 65,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {
          this.state.radiopaused? <ReactPlayer style={{display:'none'}}  playing={true}   url={this.props.radio } /> :null
        }
         
          {/* <span>{this.BoolTOstring(this.state.radiopaused)}</span> */}
        <div style={styles.top}>
          <img style={styles.img} src={leftbanner} />
          <img style={styles.img} src={rightbanner} />

          <div style={styles.col}>
            <span className="lastnum" style={{fontSize:Const.SCREEN_WIDTH * 0.37719298245614*0.20}}> {lastdrawn}</span>
            <span className="lastnumsmall">Last #</span>
          </div>
          <div style={styles.col}>
            <span style={{fontSize:Const.SCREEN_WIDTH * 0.37719298245614*0.45}}  className="num">{drawnumber}</span>
            <span className="numsmall">
              {" "}
              Game #{gameno} {gamenames[gameno - 1]}
            </span>
          </div>
          <div style={styles.col}>
            <span className="total" style={{fontSize:Const.SCREEN_WIDTH * 0.37719298245614*0.20}}> {numbersdrawn}</span>
            <span className="totalsmall">Total</span>
          </div>
        </div>

        <div style={this.middle()}>
          <div
            onClick={() => {
              this.setState({ isshowall: true });
            }}
            className="btn-img"
          >
            <img src={button1} />
            <span>Show All</span>
          </div>
          <div  onClick={() => {
              this.setState({ playerinfostatus: true });
            }} className="btn-img">
            <img src={button2} />
            <span>Player Info</span>
          </div>
          <div onClick={this.callBingo} className="btn-img">
            <img src={button3} />
            <span>Call Bingo</span>
          </div>
          <div onClick={this.showChat} className="btn-img">
            <img src={button4} />
            <span>Chat</span>
          </div>
        </div>
        <div style={styles.bottom}>{this.showCard()}</div>

        {this.showAll()}
        {this.showPlayerInfo()}
        {this.showAlert()}
          {this.showWinCard()}
          {this.showVideoComponent()}
          {this.showImageComponent()}
          {this.showPatternComponent()}
          <Chat
          close={this.chatclose}
          messages={this.state.messages}
          show={this.state.isChatShow}
          setIschatShow={this.setIschatShow}
          send={this.send}
          userid={authService.getCurrentUser(0).id}
        />
      </div>
    );
  }
}
const styles = {
  top: {
    position: "relative",
    height: Const.SCREEN_WIDTH * 0.37719298245614,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  img: {
    width: "100%",
    position: "absolute",
  },
  col: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  middle: {
    backgroundColor: "green",
    height: 64,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bottom: {
    backgroundColor: "grey",
    flex: 1,
    height:
      Const.SCREEN_HEIGHT - (Const.SCREEN_WIDTH * 0.37719298245614 + 64 + 64),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "white",
  },
  highlightTile: {
    backgroundColor: "#FB8C00",
    color: "white",
  },
  tileTitle: {
    borderWidth: 1,
    borderColor: bingocardback,
    borderStyle: "solid",
    boxSizing: "border-box",
    width: "20%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  tileTitletext: {
    fontSize: 25,
  },
};
export default Main;
