import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import axios from "axios";
import authService from "../services/auth.service";
import authHeader from "../services/auth-header";
import logo from "../assets/login-back.png";
import "./login.css";
import { Const } from "./Const";
const io = require("socket.io-client");
class PassRe extends Component {
  socket = io(Const.SOCKET_ENDPOINT, {
    transports: ["websocket"],
  });
  constructor() {
    super();
    this.state = {
      email: "",
      loading: false,
      error:''
    };
  }
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  

  

  forgotPass=()=> {
    const { email,  } = this.state;

    this.setState({ error: "", loading: true });

  
    axios
      .post("https://tv.bingo:49156/api/player/forgotpass", {
        email: email,
       
      })
      .then((response) => {
        this.setState({ error: response.data.msg ,loading: false,email:''});
      
      })
      .catch((error) => {
       

        this.setState({ error: error.response.data.msg, loading: false });
      });
  }



  logincon() {
    var h = window.innerHeight;
    var width = (h / 16) * 9;
    return {
      margin: "auto",
      height: window.innerHeight,
      width: width,
      position: "absolute",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };
  }
 
  
  render() {
    return (
      <div id="login-div">
     
        <img style={styles.imageback} src={logo} />
        <div style={this.logincon()}>
          <div style={styles.header}>
            <h6>Brought to you by the makers of </h6>
            <h2>FreeN'Fun BarBingo</h2>
            <h6>1.0.0</h6>
          </div>
          <div style={styles.form}>
            <TextField
              className="input"
              value={this.state.email}
              onChange={this.handleEmailChange}
              type="text"
              id="standard-basic"
              label="Email"
            />
        
            <Button
              className="btnwhite"
              onClick={this.forgotPass}
              variant="contained"
            >
              Request
            </Button>
            
            <span style={styles.errorTextStyle}>{this.state.error}</span>
            <div className="links">
              <p onClick={this.props.authswitch.bind(this,"0")}> Already have an account? Log in!</p>
             

             
            </div>
          </div>
          <div></div>
          {/* <button onClick={this.getCard}>Login</button> */}
        </div>
      </div>
    );
  }
}
const styles = {
  imageback: {
    // width:50
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: 0,
  },
  logincon: {
    position: "absolute",
    zIndex: 10000,
  },
  header: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: 0,
    position: "absolute",
    top: "35%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    position: "absolute",
    bottom: 50,
  },
  rule: {
    paddingBottom: 10,
  },
  errorTextStyle: {
    alignSelf: "center",

    fontSize: 18,
    color: "white",
    // marginTop:10,
    marginBottom: 10,
  },
};
export default PassRe;
