import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import axios from "axios";
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import authService from "../services/auth.service";
import authHeader from "../services/auth-header";
import logo from "../assets/img/reg-back.png";
import "./login.css";
import { Const } from "./Const";
const io = require("socket.io-client");
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
class Reg extends Component {

  socket = io(Const.SOCKET_ENDPOINT, {
    transports: ["websocket"],
  });
  constructor() {
    super();
    this.state = {
      language: 'e',
      languageindex: 0,

      email: "",
      firstname: "",
      lastname: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      dob: new Date(),
      password: "",
      password_repeat: "",
      error: "",
      loading: false,
      dp: false,
      gender: "",
      country: "USA",
      ref: "",

      labels: {
        email: ["Email", "Correo Electrónico"],
        fname: ["First name", "Primer number"],
        lname: ["Last Name", "Apellido"],
        country: [],
        street: ["Street", "Calle"],
        city: ["City", "Ciudad"],
        state: ["State", "Estado"],
        zip: ["Zip", "Codigo Postal"],
        gender: ["Gender", "Genero"],
        dob: ["D.O.B", "Fecha de nacimiento"],
        phone: ["Phone", "Telefono"],
        password: ["Password", "Contrasena"],
        confirm: ["Confirm Password", "Confirmar contrasena"],
        ref: ["How did you hear about us?", "Como supiste de nosotros?"],
        genderoption: {
          gender: ["Gender", "Genero"],
          male: ["Male", "Hombre"],
          female: ["Female", "Mujer"],
          other: ["Other", "Otro"],
          ipref: ["I prefer not to say", "Prefiero no decir"],
        },
        note: [
          " This information is used as a means of awarding you a prize and verification of identity for claiming a prize.",
          "Esta información se utiliza como un medio de que la concesión de un premio y la verificación de la identidad para reclamar el premio.",
        ],
        regbutton: ["Register", "Registrarse"],
        loglink: [
          "Already have an account? Log in!",
          "¿Ya tienes una cuenta? Entre aquí",
        ],
      },
    };
  }
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handleFirstnameChange = (e) => {
    this.setState({ firstname: e.target.value });
  };
  handleLastnameChange = (e) => {
    this.setState({ lastname: e.target.value });
  };
  handleCountryChange = (e) => {
    this.setState({ country: e.target.value });
  };
  handleStreetChange = (e) => {
    this.setState({ street: e.target.value });
  };
  handleStateChange = (e) => {
    this.setState({ state: e.target.value });
  };
  handleCityChange = (e) => {
    this.setState({ city: e.target.value });
  };
  handleZipChange = (e) => {
    this.setState({ zip: e.target.value });
  };
  handleGenderChange = (e) => {
    this.setState({ gender: e.target.value });
  };
  handleDobChange = (e) => {
    this.setState({ dob: e });
  };
  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };
  handlePasswordRepeatChange = (e) => {
    this.setState({ password_repeat: e.target.value });
  };
  handleRefChange = (e) => {
    this.setState({ ref: e.target.value });
  };

  RegisterUser = () => {
    const {
      email,
      firstname,
      lastname,
      street,
      city,
      state,
      zip,
      phone,
      dob,
      password,
      password_repeat,
      gender,
      country,
      ref,
    } = this.state;
    // alert(dob)
    this.setState({ error: "", loading: true });

    axios
      .post("https://tv.bingo:49156/api/player/sign-up", {
        email: email,
        firstname: firstname,
        lastname: lastname,
        street: street,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        dob: dob,
        password: password,
        password_repeat: password_repeat,
        gender: gender,
        country: country,
        ref: ref,
      })
      .then((response) => {
        console.log("response");

        this.setState({
          error: response.data.msg,
          loading: false,

          email: "",
          firstname: "",
          lastname: "",
          street: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          dob: new Date(),
          password: "",
          password_repeat: "",
          gender: "",
          country: "",
          ref: "",
        });
        // Handle the JWT response here
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        this.setState({ error: error.response.data.msg, loading: false });
        // Handle returned errors here
      });
  }

  validate = () => {
    // return false;

    var dt2 = new Date();
    var dt1 = new Date(this.state.dob);

    let age =
      Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
      ) / 365;

    let text = "Street";
    if (this.state.country != "USA") {
      text = "Address";
    }
    if (this.state.email === "") {
      this.setState({ error: "Please enter Email Address" });
    } else if (this.state.firstname === "") {
      this.setState({ error: "Please enter First Name" });
    } else if (this.state.lastname === "") {
      this.setState({ error: "Please enter Last Name" });
    } else if (this.state.country === "") {
      this.setState({ error: "Please select Country" });
    } else if (this.state.street === "") {
      this.setState({ error: "Please enter " + text });
    } else if (this.state.city === "" && this.state.country == "USA") {
      this.setState({ error: "Please enter City" });
    } else if (this.state.state === "" && this.state.country == "USA") {
      this.setState({ error: "Please enter State" });
    } else if (this.state.zip === "" && this.state.country == "USA") {
      this.setState({ error: "Please enter Zip" });
    } else if (this.state.gender === "") {
      this.setState({ error: "Please select Gender" });
    } else if (this.state.phone === "") {
      this.setState({ error: "Please enter Phone" });
    } else if (this.state.dob == "") {
      this.setState({ error: "Please enter Date of Birth" });
    } else if (age < 18 && this.state.country === "Jamaica") {
      this.setState({ error: "Your minimum age should be 18" });
    } else if (this.state.password === "") {
      this.setState({ error: "Please enter Password" });
    } else if (this.state.password_repeat === "") {
      this.setState({ error: "Please confirm Password" });
    } else {
      this.RegisterUser();
    }
  }





  logincon() {
    var h = window.innerHeight;
    var width = (h / 16) * 9;
    return {
      margin: "auto",
      height: window.innerHeight,
      width: width,
      position: "absolute",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };
  }


  render() {
    // const classes = useStyles();
    return (
      <div id="login-div">

        <img style={styles.imageback} src={logo} />
        <div style={this.logincon()}>

          <div style={styles.form}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                fullWidth
                  className="input"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  type="text"
                  id="standard-basic"
                  label="Email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.firstname}
                  onChange={this.handleFirstnameChange}
                  type="text"
                  id="standard-basic"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.lastname}
                  onChange={this.handleLastnameChange}
                  type="text"
                  id="standard-basic"
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className={useStyles.formControl}>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                  
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.country}
                    onChange={this.handleCountryChange}
                  >
                    <MenuItem value={"USA"}>USA</MenuItem>
                    <MenuItem value={"Jamaica"}>Jamaica</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.street}
                  onChange={this.handleStreetChange}
                  type="text"
                  id="standard-basic"
                  label={this.state.country=="USA"?"Street":"Address"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  type="text"
                  id="standard-basic"
                  label="City"
                  disabled={this.state.country=="USA"?false:true}
                /></Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className={useStyles.formControl}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                   disabled={this.state.country=="USA"?false:true}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.state}
                    onChange={this.handleStateChange}
                  >
                    <MenuItem value={"AK"}>AK</MenuItem>
                    <MenuItem value={"AZ"}>AZ</MenuItem>
                    <MenuItem value={"AR"}>AR</MenuItem>
                    <MenuItem value={"CA"}>CA</MenuItem>
                    <MenuItem value={"CO"}>CO</MenuItem>
                    <MenuItem value={"CT"}>CT</MenuItem>
                    <MenuItem value={"DE"}>DE</MenuItem>
                    <MenuItem value={"FL"}>FL</MenuItem>
                    <MenuItem value={"GA"}>GA</MenuItem>
                    <MenuItem value={"HI"}>HI</MenuItem>
                    <MenuItem value={"ID"}>ID</MenuItem>
                    <MenuItem value={"IL"}>IL</MenuItem>
                    <MenuItem value={"IN"}>IN</MenuItem>
                    <MenuItem value={"IA"}>IA</MenuItem>
                    <MenuItem value={"KS"}>KS</MenuItem>
                    <MenuItem value={"KY"}>KY</MenuItem>
                    <MenuItem value={"LA"}>LA</MenuItem>
                    <MenuItem value={"ME"}>ME</MenuItem>
                    <MenuItem value={"MD"}>MD</MenuItem>
                    <MenuItem value={"MA"}>MA</MenuItem>
                    <MenuItem value={"MI"}>MI</MenuItem>
                    <MenuItem value={"MN"}>MN</MenuItem>
                    <MenuItem value={"MS"}>MS</MenuItem>
                    <MenuItem value={"MO"}>MO</MenuItem>
                    <MenuItem value={"MT"}>MT</MenuItem>
                    <MenuItem value={"NE"}>NE</MenuItem>
                    <MenuItem value={"NV"}>NV</MenuItem>
                    <MenuItem value={"NH"}>NH</MenuItem>
                    <MenuItem value={"NJ"}>NJ</MenuItem>
                    <MenuItem value={"NM"}>NM</MenuItem>
                    <MenuItem value={"NY"}>NY</MenuItem>
                    <MenuItem value={"NC"}>NC</MenuItem>
                    <MenuItem value={"ND"}>ND</MenuItem>
                    <MenuItem value={"OH"}>OH</MenuItem>
                    <MenuItem value={"OK"}>OK</MenuItem>
                    <MenuItem value={"OR"}>OR</MenuItem>
                    <MenuItem value={"PA"}>PA</MenuItem>
                    <MenuItem value={"RI"}>RI</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"SD"}>SD</MenuItem>
                    <MenuItem value={"TN"}>TN</MenuItem>
                    <MenuItem value={"TX"}>TX</MenuItem>
                    <MenuItem value={"UT"}>UT</MenuItem>
                    <MenuItem value={"VT"}>VT</MenuItem>
                    <MenuItem value={"VA"}>VA</MenuItem>
                    <MenuItem value={"WA"}>WA</MenuItem>
                    <MenuItem value={"WV"}>WV</MenuItem>
                    <MenuItem value={"WI"}>WI</MenuItem>
                    <MenuItem value={"WY"}>WY</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                 disabled={this.state.country=="USA"?false:true}
                  className="input"
                  value={this.state.zip}
                  onChange={this.handleZipChange}
                  type="text"
                  id="standard-basic"
                  label="Zip"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className={useStyles.formControl}>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.gender}
                    onChange={this.handleGenderChange}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                    <MenuItem value={"I prefer not to say"}>I prefer not to say</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <Grid item xs={6}> */}
                  <KeyboardDatePicker

                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of Birth"
                    format="MM/dd/yyyy"
                    value={this.state.dob}
                    onChange={this.handleDobChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  {/* </Grid> */}
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.phone}
                  onChange={this.handlePhoneChange}
                  type="text"
                  id="standard-basic"
                  label="Phone"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  type="password"
                  id="standard-basic"
                  label="Password"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="input"
                  value={this.state.password_repeat}
                  onChange={this.handlePasswordRepeatChange}
                  type="password"
                  id="standard-basic"
                  label="Confirm Password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                fullWidth
                  className="input"
                  value={this.state.ref}
                  onChange={this.handleRefChange}
                  type="text"
                  id="standard-basic"
                  label="How did you here about us?"
                />
              </Grid>
              <Grid item xs={12}>
              <Button
              fullWidth
                className="btnwhite"
                onClick={this.validate}
                variant="contained"
              >
                Register
            </Button>
</Grid>

{/* <Grid item > */}
              <span style={styles.errorTextStyle}>{this.state.error}</span>
             
             {/* </Grid> */}
             <Grid item xs={12}>
              <div className="links">
                <p onClick={this.props.authswitch.bind(this, "0")}> Already have an account? Log in!</p>



              </div>
              </Grid>
            </Grid>
          </div>
          <div></div>
          {/* <button onClick={this.getCard}>Login</button> */}
        </div>
      </div>
    );
  }
}
const styles = {
  imageback: {
    // width:50
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: 0,
  },
  logincon: {
    position: "absolute",
    zIndex: 10000,
  },
  header: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: 0,
    position: "absolute",
    top: "35%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    position: "absolute",
    // bottom: 50,
  },
  rule: {
    paddingBottom: 14,
  },
  errorTextStyle: {
    textAlign: "center",
width:'100%',
    fontSize: 14,

    color: "white",
    // marginTop:10,
    marginTop: 10,
  },
};
export default Reg;
