import React, { Component, Fragment } from "react";
import ReactPlayer from 'react-player'
import { Const } from "./Const";


class VideoCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  
  }

  componentWillUnmount() {}
  componentDidMount() {}

 
  videoend = () => {
  
   
      this.props.setPlayVideo(false);
  
   
  };
  render() {
    const { email, password, error, loading, mainNo } = this.state;
    const { form, section, errorTextStyle, container } = styles;

    return (
      <Fragment>
        {/* {this.props.frombeforehostlist?
            
      
      
        <Button onPress ={() => {
          this.props.setPlayVideo(false);
        }} style={{position:'absolute' ,zIndex:2000000000001,top:0,right:0,color:'white'}} transparent>
            <Icon name="close" style={{color:'white'}} />
          </Button>
     
        :null} */}
        <ReactPlayer onEnded={this.videoend} width={Const.SCREEN_WIDTH} height={Const.SCREEN_HEIGHT-64}    playing={this.props.playpause}   style={styles.video} url={this.props.url } />
        {/* <Video
        ref={(r) => (this.vid = r)}
        source={{ uri: this.props.url }}
        rate={1.0}
        volume={1.0}
        muted={false}
        resizeMode="contain"
        shouldPlay={this.props.playpause}
       
        onPlaybackStatusUpdate={(playbackStatus) =>
          this._onPlaybackStatusUpdate(playbackStatus)
        }
        style={styles.video}
      /> */}
      </Fragment>
      
    );
  }
}

const styles = {
  video: {
    width: "100%",
    height:"100%",
    backgroundColor: "black",
    position:'absolute',
    zIndex:100000
  },
};

export { VideoCon };
