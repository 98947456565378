import React, { Fragment } from "react";
var classNames = require('classnames');
const activecolor = "#B41487";
const activetextcolor = "white";
const Cardnum = ({ key_, value_, method, active, cardBoxStyle,isShade,isShadeSecondary,bingocardback,activestyle }) => {
  // alert(JSON.stringify(activestyle))
 
//   var whichstyle = styles.num;
//   if (key_ == "13") {
//     whichstyle = [styles.middlenum];
//   }

  var Classes = classNames({
    'buttonnum': true,  
    'shade':isShade==true,
    'shadeSec':isShadeSecondary==true
  });

  var activeclasses = classNames({
    'num': key_!= "13",
    'middlenum': key_ == "13",
    'activetext':active==true,
    'inactivetext':active==false
  });

  return (
  
      <div style={cardBoxStyle}>
        <div
          disabled={active ? true : false}
        //   style={[styles.buttonnum,isShade?styles.shade:styles.none,isShadeSecondary?styles.shadeSec:styles.none]}
          className={Classes}
         
          onClick={method}
        >
          <div  style={active?activestyle:styles.inactive} ></div>
     


          <div
           className={activeclasses}
          >
            {value_}
          </div>

      
        </div>
     
      </div>

  );
};

const styles = {
  active: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    resizeMode: 'contain', // or 'stretch'
    position: "absolute",
    width: '85%',
    height: '85%',
   

    backgroundColor:'#B41487',
    borderRadius:1000,
   
   
  },
  activeText: {
    color: "white",
    
  },
  inactive: {
    display: "none",
  },
  shade:{
    backgroundColor: '#509ce650',
   
  },
  shadeSec:{

    backgroundColor:'#b7124a50',
  },
  none:{},
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  box: {
    backgroundColor: "blue",
    borderColor: "#e8e8e8",
    width: "20%",
    aspectRatio: 1,
    // borderWidth: 1,
  },

//   buttonnum: {
//     width: "100%",
//     height: "100%",
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   num: {
//     fontSize: 40,
//     fontWeight: "700",
//     zIndex: 1,

    
   
//   },
//   middlenum: {
//     fontSize: 15,
//     color: "white",
//   },
};
export { Cardnum };
