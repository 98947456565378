import React, { Component } from "react";
import headerimg from "../assets/img/header.png";
import cardback from "../assets/img/backtrans.png";
import brush from "../assets/img/brush.png";
import { Const } from "./Const";
var classNames = require("classnames");
class WinCardCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  render() {

 

    const cardnums = this.props.pattern.map((ele, index) => {
     
      var activeclasses = classNames({
        'activediv': ele.iswin,
       'aaa': !ele.iswin,
       'middlenum':index==12,
       'num':index!=12
      });
  
     return(
      <div style={{ width: "20%" }}>
      <div style={styles.cardBoxStyle}>
        <img
          src={brush}
          style={ele.iswin ? styles.active : styles.inactive}
        />

        <div className={activeclasses}>{ele.num}</div>
      </div>
    </div>
     )
     
   
   });
    return (
      <div style={styles.wcard}>
        <div style={{width:'90%'}}>
            <div
             style={{backgroundImage:`url(${cardback})`,width:Const.SCREEN_WIDTH-50,height:'100%',margin:'auto',backgroundPosition:'center',backgroundSize:'cover'}}
            >

            <div style={{ width: "100%", aspectRatio: 5 }}>
              <div style={styles.boxheadcontainer}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    resizeMode: "contain",
                    zIndex: -1,
                   
                    
                  }}
                  src={headerimg}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {cardnums}
            </div>
            </div>
            </div>
      </div>
    );
  }
}

const styles = {
  aaa:{},
  cardback: {
    resizeMode: "cover",
    width: '100%',
   
  },
  wcard: {
    width:Const.SCREEN_WIDTH,
    height:Const.SCREEN_HEIGHT-64,
    backgroundColor: "black",
    position: "absolute",
    zIndex: 10000,
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
  },
  boxheadcontainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle:'solid',
  },
  cardBoxStyle: {
    width: "100%",
    position:'relative',
    height:(Const.SCREEN_WIDTH*.9)/5,
    borderWidth: 1,
    borderStyle:'solid',
    // backgroundColor: "white",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    resizeMode: "contain", // or 'stretch'
    position: "absolute",
    width: "100%",
    // height: '100%',
    // aspectRatio:1,
    // backgroundColor: activecolor,
    // borderRadius: 25,
    // position:'absolute'
  },
  activediv: {
    fontSize: 40,
    fontWeight: "700",
    zIndex: 10000,
    color: "white",
  },
  inactive: {
    display: "none",
  },
  // cardback: {
  //   resizeMode: "cover",
  //   width: "100%",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   height: "100%",
  // },
  num: {
    fontSize: 40,
    fontWeight: "700",
    zIndex: 1,

    
   
  },
  middlenum: {
    fontSize: 20,
    // color: "white",
  },
};

export { WinCardCon };
