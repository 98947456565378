
export const Const={
    API_URL : "https://tv.bingo:49156/api/player/",
    SOCKET_ENDPOINT:"https://tv.bingo:49156",
    SCREEN_WIDTH:getWidth(),
    SCREEN_HEIGHT:window.innerHeight,
   DIALOG_WIDTH:getWidth()- 50
}

function getWidth()
{
    var h = window.innerHeight;

        var width = (h / 16) * 9;
        return width;
}