import axios from "axios";
import {Const} from "../components/Const"

class AuthService {
  login(email, password) {
    var d = new Date();    
    return axios
      .post(Const.API_URL + "login", {
        email: email,
        password: password,
        time: d.toLocaleDateString() + " " + d.toLocaleTimeString(),
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
        }

        return response.data;
      })
      
      
      
      
      
      
      
      ;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }

  register(username, email, password) {
    return axios.post(Const.API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();