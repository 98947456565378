import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Const } from "./Const";
import authService from "../services/auth.service";
import UIfx from "uifx";
import readyAudio from "../assets/sounds/ready.mp3";

const ready = new UIfx(readyAudio, {
  volume: 0.4, // number between 0.0 ~ 1.0
  throttleMs: 100,
});
const io = require("socket.io-client");

class Hostlist extends Component {
  socket = io(Const.SOCKET_ENDPOINT, {
    transports: ["websocket"],
  });
  constructor() {
    super();
    this.state = {
      rand: "",
      hosts: [],
      visible: false,
      temhost: "",
      password: "",
      pin: "",
      hostid: "",
      tmprd: "",
      region: "",
      err: "",
    };
  }
  componentWillUnmount() {
    this.socket.disconnect();
  }

  async playReady() {
    ready.play();
    // const soundObject = new Audio.Sound();
    // try {
    //   await soundObject.loadAsync(require("../../assets/sounds/ready.mp3"), {
    //     volume: 1,
    //   });
    //   await soundObject.playAsync();
    // } catch {}
  }
  componentDidMount() {
    this.socket.on("connect", () => {
      //here no need to join channel

      this.socket.emit("requestonline", {
        device: "app",
        user_id: authService.getCurrentUser().id,
      });
      this.socket.emit("logoff", {
        rand: this.state.rand,
        user_id: authService.getCurrentUser().id,
      });
    });

    this.socket.on("imonline_", (data) => {
      console.log(data);
      let gametype = 0;
      if (this.props.type == "couch") {
        // couch
        gametype = 1;
      } else if (this.props.type == "bar") {
        // barbingo
        gametype = 0;
      } else if (this.props.type == "loteria") {
        //other,loteria
        gametype = 2;
      }

      if (data.type == gametype) {
        // this.setState({ refreshing: false });
        var array = this.state.hosts;

        var exist = false;
        var BreakException = {};
        
        if (data.status.split(" ")[0] == "Ready" && data.online == 1) {
          this.playReady();
        }
        try {
          array.forEach((element) => {
            // alert(element.nickname+'old')

            if (element.nickname === data.nickname) {
              element.status = data.status;
              element.gameno = data.gameno;
              element.drawn = data.drawn;
              element.color = data.color;
              element.online = data.online;
              element.avatar = data.avatar;
              element.pin = data.pin;
              element.hostid = data.hostid;
              element.radiolink = data.radiolink;
              element.type = data.type;
              element.region = data.region;
              exist = true;
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }

        if (!exist) {
          if (data.nickname) {
            if (
              authService.getCurrentUser().country.toLowerCase() == "jamaica" &&
              data.region == "jamaica"
            ) {
              array = array.concat(data);
            } else if (
              authService.getCurrentUser().country.toLowerCase() == "usa" &&
              data.region != "jamaica"
            ) {
              array = array.concat(data);
            } else {
            }
          }
        }

        this.setState({ hosts: [] });
        this.setState({ hosts: array });
      }
      // alert(data.type)

      // console.log('------------------------------------------------------------------------');

      // console.log(this.state.hosts);
    });
  }
  selectHost(nickname, gameno, drawn, pin, hostid, radiolink, region) {
    // if (gameno > 5 && drawn >= 1) {
    //   alert(
    //     "Sorry," + gameno + "th game has already begun. No new players allowed."
    //   );
    // } else if (gameno > 6) {
    //   alert(
    //     "Sorry," + gameno + "th game has already begun. No new players allowed."
    //   );
    // } else {

    if (drawn > 3) {
      alert("HOLD! Host is midgame on game #" + gameno + ". Please Wait.");
    } else {
      if (pin != "") {
        this.setState({
          temhost: nickname,
          visible: true,
          pin: pin,
          hostid: hostid,
          tmprd: radiolink,
          region: region,
        });
      } else {
        this.props.setChannel(nickname, hostid, radiolink);
      }

      //
    }
    // }
  }
  handleClose = () => {
    this.setState({ visible: false });
  };
  handlePin = (e) => {
    this.setState({ password: e.target.value });
  };
  join = () => {
    if (
      this.state.password == this.state.pin ||
      this.state.password == this.props.masterpin
    ) {
      if (this.state.region == "linked-virtual") {
        // alert('fdf')
        this.setState({ visible: false });
      } else {
        // AsyncStorage.setItem("answer", "");

        localStorage.setItem("answer", "");
        this.props.setChannel(
          this.state.temhost,
          this.state.hostid,
          this.state.tmprd
        );
      }
    } else {
      this.setState({ err: "Invalid Password" });
    }
  };

  


  render() {
    const { hosts, visible } = this.state;
    var nickarr = [];

    const listItems = hosts.map((host) => {
      if (host.online == 1 && !nickarr.includes(host.nickname)) {
        nickarr.push(host.nickname);
        //   if (this.state.gametype[0] == true && host.type == 1) {
        return (
          <div key={host.nickname}>
            <ListItem
              alignItems="flex-start"
              onClick={this.selectHost.bind(
                this,
                host.nickname,
                host.gameno,
                host.drawn,
                host.pin,
                host.hostid,
                host.radiolink,
                host.region
              )}
            >
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={host.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={host.nickname}
                secondary={host.status + "-" + host.type}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>

          //   <Hostitem
          //     method={this.selectHost.bind(
          //       this,
          //       host.nickname,
          //       host.gameno,
          //       host.drawn,
          //       host.pin,
          //       host.hostid,
          //       host.radiolink,
          //       host.region
          //     )}
          //     key={host.nickname}
          //     nickname={host.nickname}
          //     status={host.status}
          //     color={host.color}
          //     avatar={host.avatar}
          // type={host.type}
          //   />
        );
        //   } else if (this.state.gametype[1] == true && host.type == 0) {
        //     return (
        //     //   <Hostitem
        //     //     method={this.selectHost.bind(
        //     //       this,
        //     //       host.nickname,
        //     //       host.gameno,
        //     //       host.drawn,
        //     //       host.pin,
        //     //       host.hostid,
        //     //       host.radiolink,
        //     //       host.region
        //     //     )}
        //     //     key={host.nickname}
        //     //     nickname={host.nickname}
        //     //     status={host.status}
        //     //     color={host.color}
        //     //     avatar={host.avatar}
        //     //     type={host.type}
        //     //   />
        //     );
        //   } else if (this.state.gametype[2] == true && host.type == 2) {
        //     return (
        //     //   <Hostitem
        //     //     method={this.selectHost.bind(
        //     //       this,
        //     //       host.nickname,
        //     //       host.gameno,
        //     //       host.drawn,
        //     //       host.pin,
        //     //       host.hostid,
        //     //       host.radiolink,
        //     //       host.region
        //     //     )}
        //     //     key={host.nickname}
        //     //     nickname={host.nickname}
        //     //     status={host.status}
        //     //     color={host.color}
        //     //     avatar={host.avatar}
        //     //     type={host.type}
        //     //   />
        //     );
        //   }
      }
    });

    return (
      <div
        style={{
          backgroundColor: "white",
          height: window.innerHeight - 65,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Dialog
          open={visible}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
            <TextField
              fullWidth
              className="input"
              value={this.state.password}
              onChange={this.handlePin}
              type="password"
              id="standard-basic"
              label="Please Enter Password to Join"
            />
            <div>{this.state.err}</div>
          </DialogContent>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={this.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button className="buttonFill" onClick={this.join} color="primary">
              Join
            </Button>
          </DialogActions>
        </Dialog>

        <List style={styles.root}>{listItems}</List>
      </div>
    );
  }
}
const styles = {
  root: {
    width: "100%",
    // maxWidth: "36ch",
    // backgroundColor: "red",
  },
  inline: {
    display: "inline",
  },
};
export default Hostlist;
