import React, { Component } from 'react';
import axios from 'axios';
import authService from '../services/auth.service';
import authHeader from '../services/auth-header';
import Hostlist from './Hostlist';
import Main from './Main';
import { VideoCon } from './VideoCon';
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: '', //which category couch,bar virtual,private
			channel: '',
			hostid: '',
			radio: '',
			hexcolor: this.props.mc,
			mute: this.props.mute,
			playenwb: false,
			radiomute: this.props.radiomute,
		};
	}
	updateType = (type) => {
		this.setState({ type: type,channel:'' });

	};
	setChannel = (channel, hostid, radio) => {
		//  let asnwer =await AsyncStorage.getItem("answer")
		// alert( this.state.asnwer)

		this.setState({
			channel: channel,
			hostid: hostid,
			radio: radio,
		});

		this.props.getRadioMethod(radio);
	};
	componentDidMount() {
		this.props.onRef(this);
	}
	setNewBPlay = () => {
		this.setState({ playenwb: !this.state.playenwb });
		// this.props.method()
	};
	updatecolor = (c) => {
	this.setState({hexcolor:c})
	};
	updateSOundsOn = (c) => {
	
		this.setState({mute:c})
		};
	stopV = () => {
		this.setState({ playenwb: false });
		// this.props.method()
	};
	radioonOff = (val) => {
		
		this.setState({ radiomute: val });
		this.childMain.toggleradio(val)
		// this.props.method()
	};
	// showVideoCom=()=> {
	// 	// alert(this.state.playenwb)
	// 	if (this.state.playenwb==true) {

	// 		return (
	// 			<VideoCon
	// 				frombeforehostlist={true}
	// 				// setPlayVideo={this.setPlayVideo}
	// 				url={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/newb.mp4?t='}
	// 				playpause={true}
	// 			/>
	// 		);
	// 	}
	// }
	render() {
		const { type } = this.state;
		if (type == '') {
			return (
				<div
					style={{
						backgroundColor: '#B41487',
						height: window.innerHeight - 65,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{this.state.playenwb ? (
						<VideoCon
							frombeforehostlist={true}
							// setPlayVideo={this.setPlayVideo}
							url={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/newb.mp4?t='}
							playpause={true}
						/>
					) : null}

					<div>
						<video width="100%" controls>
							<source
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/v.mp4'}
								type="video/mp4"
							/>
						</video>
					</div>
					<div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
						<div onClick={this.setNewBPlay}>
							<img
								style={styles.banner}
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/newb.png'}
							/>
						</div>
						<div onClick={this.updateType.bind(this, 'couch')}>
							<img
								style={styles.banner}
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/1.png'}
							/>
						</div>
						<div onClick={this.updateType.bind(this, 'bar')}>
							<img
								style={styles.banner}
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/2.png'}
							/>
						</div>
						<div onClick={this.updateType.bind(this, 'bar')}>
							<img
								style={styles.banner}
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/3.png'}
							/>
						</div>
						<div onClick={this.updateType.bind(this, 'loteria')}>
							<img
								style={styles.banner}
								src={'https://test.tv.bingo/server/files/appimages/' + this.props.country + '/4.png'}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.state.channel == '') {
				return <Hostlist setChannel={this.setChannel} type={this.state.type} />;
			} else {
				return (
					<Main
					onRef={(ref) => (this.childMain = ref)}
						channel={this.state.channel}
						mute={this.state.mute}
						hexcolor={this.state.hexcolor}
						hostid={this.state.hostid}
						radio={this.state.radio}
						radiomute={this.state.radiomute}
					/>
				);
			}
		}
	}
}
const styles = {
	banner: {
		width: '100%',
	},
};
export default Home;
