import React, { Component, Fragment } from "react";

import { Cardnum } from "./Cardnum";
import headerimg from "../assets/img/header.png";
import cardback from "../assets/img/backtrans.png";
import { Const } from "./Const";

import UIfx from 'uifx'
import okAudio from '../assets/sounds/ok.wav'
import notOkAudio from '../assets/sounds/no.wav'

const ok = new UIfx(
    okAudio,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  )

  const notOk = new UIfx(
    notOkAudio,
    {
      volume: 0.4, // number between 0.0 ~ 1.0
      throttleMs: 100
    }
  )
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: this.props.card,
      mc: "#ddd",
      active: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      shades: {
        1: [],
        2: [],
        3: [],
        4: [0, 4, 20, 24],
        5: [0, 1, 2, 3, 4, 5, 10, 15, 20, 21, 22, 23, 24],
        6: [],
        7: [],
        8: [],
        9: [2, 7, 10, 11, 13, 14, 17, 22],
        10: [],
      },
      shadesSecondary: {
        1: [],
        2: [],
        3: [],
        4: [6, 8, 16, 18],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
      },
    };
    console.log(this.props.test);
    console.log(this.props.allnumbers);

    this.clclickNumickNum = this.clickNum.bind(this);
  }
  async clickNum(index, value) {
    // const soundObject = new Audio.Sound();
      

    if (this.props.allnumbers.includes(value)) {
      var tempact = this.state.active.slice();
      tempact[index] = true;
      this.setState({ active: tempact });

    //   try {
    //     await soundObject.loadAsync(require("../../assets/sounds/ok.wav"));
        if(this.props.mute){
        ok.play()
        }
     
    //   } catch {}
    } else {
    //   try {
    //     await soundObject.loadAsync(require("../../assets/sounds/no.wav"));
        if(this.props.mute){
         notOk.play()
        }
    //   } catch {}
    }
  }
  cardHeadStyle = function (params) {
    return {
      backgroundColor: this.props.red,
      width: "20%",
        height:(Const.SCREEN_HEIGHT-(Const.SCREEN_WIDTH*0.37719298245614+64+64))/6
    };
  };
  cardBoxStyle = function (params) {
    return {
      // backgroundColor: ""this.props.bingocardback"",
      borderColor: this.props.bingocardback,
      width:Const.SCREEN_WIDTH/5-10,
      height:(Const.SCREEN_HEIGHT-(Const.SCREEN_WIDTH*0.37719298245614+64+64))/6,
      borderWidth: 1,
      borderStyle:'solid',
      float:'left',
      boxSizing:'border-box'
    };
  };

  boxheadcontainer=function(params) {
    return {width: "100%",
    height: (Const.SCREEN_HEIGHT-(Const.SCREEN_WIDTH*0.37719298245614+64+64))/6,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    boxSizing:'border-box',
    borderWidth:1,
    borderStyle:'solid',
    borderColor: this.props.bingocardback,}
  }
  activeStyle = function (params) {
    return {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      resizeMode: "contain", // or 'stretch'
      position: "absolute",
      width:((Const.SCREEN_HEIGHT-(Const.SCREEN_WIDTH*0.37719298245614+64+64))/6)*0.85,
      height: ((Const.SCREEN_HEIGHT-(Const.SCREEN_WIDTH*0.37719298245614+64+64))/6)*0.85,

      backgroundColor: this.props.activemc,
      borderRadius: 1000,
    };
  };
  render() {
    const { card } = this.state;
    const cardnums = card.map((ele, index) => (
 
      <Cardnum
        key={ele.key}
        active={this.state.active[index]}
        key_={ele.key}
        value_={ele.value}
        method={this.clickNum.bind(this, ele.key - 1, ele.value)}
        bingocardback={this.props.bingocardback}
        cardBoxStyle={this.cardBoxStyle()}
        activestyle={this.activeStyle()}
        isShade={this.state.shades[this.props.gameno].includes(index)}
        isShadeSecondary={this.state.shadesSecondary[
          this.props.gameno
        ].includes(index)}
      />
    ));

    return (
   
      
      <div  style={{backgroundImage:`url(${cardback})`,width:Const.SCREEN_WIDTH-50,height:'100%',margin:'auto',backgroundPosition:'center',backgroundSize:'cover'}}>

    
      {/* <img src={cardback}/> */}
        {/* <ImageBackground
            source={require("../../assets/img/cardback.png")}
            style={styles.cardback}
          > */}

        <div style={{ width: "100%", aspectRatio: 5 }}>
          <div style={this.boxheadcontainer()}>
            <img
              style={{
                width: "100%",
                height: "100%",
                resizeMode: "contain",
                zIndex: -1,
                borderWidth: 1,
                borderColor: this.props.bingocardback,
              }}
              src={headerimg}
            />
          </div>
        </div>
        {cardnums}
        </div>
    
    );
  }
}

const styles = {
  numback: {},
  boxhead: {
    backgroundColor: "#e8e8e8",
    width: "20%",
    aspectRatio: 1,
  },
//   boxheadcontainer: {
//     width: "100%",
//     height: "100%",
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//     boxSizing:'border-box',
//     borderWidth:1,
//     borderStyle:'solid',
//     borderColor: this.props.bingocardback,
//   },
  boxheadtitle: {
    color: "#B41487",
    fontSize: 65,
    fontWeight: "bold",
  },
  cardback: {
//    position:'relative',
    width: "100%",
  },
};

export { Card };
