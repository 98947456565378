import logo from './logo.svg';
import React, { Component } from 'react';
import {  Route, Link } from 'react-router-dom';
import './App.css';
import AuthService from './services/auth.service';
import Login from './components/Login';
import Home from './components/Home';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import SettingIcon from '@material-ui/icons/Settings';
import NotiIcon from '@material-ui/icons/Notifications';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Switch, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Const } from './components/Const';
import Axios from 'axios';
import authService from './services/auth.service';
import ScrollToBottom from "react-scroll-to-bottom";
import { SketchPicker } from 'react-color';
import PassRe from './components/PassRe';
import Reg from './components/Reg';

// import authService from "./services/auth.service";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },

// }));

class App extends Component {
	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);

		this.state = {
			currentUser: undefined,
			type: '',
			hexcolor: 'purple',
			shownewhome: false,
      radiolink: '',
      isradioon:true,
      shownoti:false,
      noti:[],
      showsettings:false,
      markercolor:'#b7124a',
      soundsOn:true,
      loadnoti:false,
      reg:false,
      passre:false
		};
	}
	componentDidMount=()=> {
		const user = AuthService.getCurrentUser();
		if (user) {
			this.setState({
				currentUser: user,
			});
    }
    if(localStorage.getItem('mc')==null)
    {
      localStorage.setItem('mc','#b41487')
    }
    this.setState({markercolor:localStorage.getItem('mc')})

    var val=true;
    if(localStorage.getItem('soundson')=='false'){
     
      val=false
    }
    // alert(val)
    this.setState({soundsOn:val})
    // this.childhome.updateSOundsOn(val);
	}
	logOut() {
		AuthService.logout();
		this.setState({ currentUser: undefined });
  }
  
  showNotifications = () => {
    if (this.state.shownoti) {
      return (
        <Dialog
          open={true}
        
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Notifications</DialogTitle>
          <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
            <DialogContentText style={{ width: Const.DIALOG_WIDTH }} >
            <ScrollToBottom className="msgcon" >
              

           {

             this.state.loadnoti?<div style={{width:'100%',display:"flex",justifyContent:"center"}}><CircularProgress color={this.state.gamecolor} /></div>:
             this.state.noti.map((n) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomColor: "#ddd",
                    borderBottomWidth: 1,
                    paddingBottom: 10,
                    borderBottomStyle:'solid'
                  }}
                >
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontWeight: "bold",color:'#565656' }}>{n.title}</span>
                    <span note style={{fontSize:'small'}}>
                      {new Date(n.datetime).toUTCString()}
                    </span>
                  </div>

                  <span style={{color:'#565656'}}>{n.body}</span>
                </div>
              );
            })
           } 


            {/* {} */}
              
              
              </ScrollToBottom>
          </DialogContentText>
          
          </DialogContent>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={() => {
                this.setState({ shownoti: false });
              }}
              style={{ width: "100%" }}
            >
              Hide
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };
  handleChangeMarkerColorComplete=(color)=>{
    this.setState({markercolor:color.hex})

    localStorage.setItem('mc',color.hex)
    this.childhome.updatecolor(color.hex);
  }
  handleChangeSwitch=()=>{
    this.setState({soundsOn:!this.state.soundsOn})
    localStorage.setItem('soundson',!this.state.soundsOn)
    this.childhome.updateSOundsOn(!this.state.soundsOn);
  }
  showSettings = () => {
    if (this.state.showsettings) {
      return (
        <Dialog
          open={true}
        
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Settings</DialogTitle>
          <div style={{ width: Const.DIALOG_WIDTH,display:"flex",alignItems:"center",justifyContent:'center',flexDirection:'column' }}>
         
            <SketchPicker color={this.state.markercolor} onChangeComplete={ this.handleChangeMarkerColorComplete } />
            <FormControlLabel
        style={{display:'flex',          width: 200,          justifyContent: 'space-between',marginTop:20,marginLeft:0}}
          control={      <Switch
            checked={this.state.soundsOn}
            onChange={this.handleChangeSwitch}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />}
          label="Sounds"
          labelPlacement="start"
        />



      
          
          </div>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={() => {
                this.setState({ showsettings: false });
              }}
              style={{ width: "100%" }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

	wrapstyle() {
		var h = window.innerHeight;

		var width = (h / 16) * 9;
		return {
			margin: 'auto',
			height: window.innerHeight,
			width: width,
		};
	}
	upateHomeIcon = () => {
		alert('kkk');
	};
	updateType = (type) => {
		this.childhome.updateType(type);
	};
	callchild = (val) => {
		this.childhome.updateType(val);
		this.childhome.stopV();
	};


  updateradiolink=(val)=>{
    this.setState({radiolink:val})
  }
  radioSwitch=()=>{
    this.setState({isradioon:!this.state.isradioon})
    this.childhome.radioonOff(!this.state.isradioon);
  }
  openNoti=()=> {
    this.setState({ loadnoti: true });
    Axios({
      method: "GET",
      url:
        "https://tv.bingo:49156/api/player/get-noti?country=" +
        authService.getCurrentUser().country,
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Headers": "*",
      // },
    })
      .then((response) => {
        console.log(response.data.rules);

        // alert(JSON.stringify(response));
        this.setState({
          noti: response.data.rules,
          loadnoti: false,
        });
      })
      .catch((error) => {
        // alert(error);
        // console.log(error)
        this.setState({
          error: "Error retrieving data",
          loadnoti: false,
        });
      });
    this.setState({ shownoti: true });
  }
  openSettings=()=>{
    this.setState({showsettings:true})
  }
  authswitch=(var_)=>{
    if(var_=="0"){
      this.setState({passre:false,reg:false})
    }
    else if(var_=="1"){
      this.setState({passre:true,reg:false})
    }
    else if(var_=="2"){
      this.setState({passre:false,reg:true})
    }
  
  }
  whichForm=()=>{
    if(this.state.reg){
      return (<Reg  authswitch={this.authswitch}/>)
    }
    else if(this.state.passre){
      return (<PassRe  authswitch={this.authswitch}/>)
    }
    else{
      return (<Login authswitch={this.authswitch}/>)
    }
  }
	render() {
		const { currentUser } = this.state;
		// const classes = useStyles();
		return (
			<div style={this.wrapstyle()}>
        {this.showNotifications()}
        {this.showSettings()}
				<div>
					{currentUser ? (
						<AppBar position="static">
							<Toolbar>
								<IconButton
									onClick={this.callchild.bind(this, '')}
									edge="start"
									style={styles.menuButton}
									color="inherit"
									aria-label="menu"
								>
									<HomeIcon />
								</IconButton>

								<Typography variant="h6" style={styles.title}>
									FNFCOUCHBINGO
								</Typography>

								{this.state.radiolink != '' ? (
									<IconButton
                  
                  onClick={this.radioSwitch}
										// style={styles.menuButton}
										color="inherit"
									>
                    {
                      this.state.isradioon?	<VolumeUpIcon />:<VolumeOffIcon/>
                    }
									
									</IconButton>
								) : null}

								<IconButton
                onClick={this.openNoti}
									// style={styles.menuButton}
									color="inherit"
								>
									<NotiIcon />
								</IconButton>
								<IconButton
                onClick={this.openSettings}
									// style={styles.menuButton}
									color="inherit"
								>
									<SettingIcon />
								</IconButton>
								<IconButton
									edge="end"
									// style={styles.menuButton}
									color="inherit"
									onClick={this.logOut}
								>
									<ExitToAppIcon />
								</IconButton>
							</Toolbar>
						</AppBar>
					) : null}
				</div>





				
					{currentUser ? (
						<Home
            onRef={(ref) => (this.childhome = ref)}
            country={currentUser.country}
            method={this.upateHomeIcon}
            getRadioMethod={this.updateradiolink}
            mc={this.state.markercolor}
            mute={this.props.soundsOn}
            radiomute={this.state.isradioon}

          />
					) :
				this.whichForm()
					
          
          }
	
			
			</div>
		);
	}
}


const styles = {
	wrapper: {
		maxWidth: 400,
		backgroundColor: 'red',
		position: 'absolute',
	},
	menuButton: {
		// marginRight: 2,
	},
	title: {
		flexGrow: 1,
		fontSize: 15,
	},
};
export default App;
