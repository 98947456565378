import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import axios from "axios";
import authService from "../services/auth.service";
import authHeader from "../services/auth-header";
import logo from "../assets/login-back.png";
import "./login.css";
import { Const } from "./Const";
const io = require("socket.io-client");
class Login extends Component {
  socket = io(Const.SOCKET_ENDPOINT, {
    transports: ["websocket"],
  });
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      loading: false,
      rules: [],
      visible:false,
      error:''
    };
  }
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handlePassChange = (e) => {
    this.setState({ password: e.target.value });
  };
  showRulesModal = () => {
    if (this.state.visible) {

      const listItems = this.state.rules.map((rule) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
        
            <div style={styles.rule}>{rule.description}</div>
          </div>
        );
        })



      return (
        <Dialog
          open={true}
        
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Rules</DialogTitle>
          <DialogContent style={{ width: Const.DIALOG_WIDTH }}>
            <DialogContentText style={{ width: Const.DIALOG_WIDTH }} >
            <div className="msgcon" >
              

           


            {listItems}
              
              
              </div>
          </DialogContentText>
          
          </DialogContent>
          <DialogActions>
            <Button
              className="buttonoutline"
              onClick={() => {
                this.setState({ visible: false });
              }}
              style={{ width: "100%" }}
            >
              Hide
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };
  login = () => {
    const { email, password } = this.state;

    authService.login(email, password).then(
      () => {
        // this.props.history.push("/");
        window.location.reload();
      },
      (error) => {
        console.log(error.response.data.msg);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          error:error.response.data.msg,
        });


        if (error.response.data.id != null) {
          // alert('Already Login or not properly signout.signout from all devices?')
          // alert(error.response.data.id);
          if (window. confirm("You are already logged in via another device or were not properly logged out of our system from a previous session. Would you like to sign out of all devices to allow login via this device?")) {
            this.logoff(error.response.data.id);
          } else {
            // txt = "You pressed Cancel!";
          }



          // Alert.alert(
          //   "Alert",
          //   "You are already logged in via another device or were not properly logged out of our system from a previous session. Would you like to sign out of all devices to allow login via this device?",
          //   [
          //     {
          //       text: "Yes",
          //       onPress: () => {
          //         this.logoff(error.response.data.id);
          //       },
          //     },
          //     { text: "No", onPress: () => { } },
          //   ],
          //   { cancelable: false }
          // );
        }
      }
    );
  };

  componentDidMount(){
    axios({
      method: "GET",
      url: "https://tv.bingo:49156/api/player/get-rules",
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Headers": "*",
      // },
    })
      .then((response) => {
        // console.log(response);

        // alert(JSON.stringify(response));
        this.setState({
          rules: response.data.rules,
        });
      })
      .catch((error) => {
        // alert(error);
        // console.log(error)
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  // getCard() {
  //   return axios
  //     .get(API_URL + "get-card", {
  //       user: "5",
  //       headers: authHeader(),
  //     })
  //     .then((response) => {
  //       return response.data;
  //     });
  // }
  logincon() {
    var h = window.innerHeight;
    var width = (h / 16) * 9;
    return {
      margin: "auto",
      height: window.innerHeight,
      width: width,
      position: "absolute",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };
  }
  showrules=()=>{
   
    this.setState({visible:true})
  }
  logoff(id) {
    this.socket.emit("logoff", {
      user_id: id,
    });

    axios
      .post(
        "https://tv.bingo:49156/api/player/updatsocket",
        {
          id: id,
        },
        {
          headers: {
            // 'Content-Type': 'text/xml',
            // 'RequestId': requestId,
            // 'Authorization': authHeader,
            // SOAPAction: 'http://www.retaildirections.com/RDService',
          },
          // httpsAgent: new https.Agent({
          //   rejectUnauthorized: false,
          // }),
        }
      )
      .then((response) => {
        //  alert(response.data.msg)
        this.setState({ error: response.data.msg });
      })
      .catch((error) => {
        // alert(error);
        console.log(error);
      });
  }
  render() {
    return (
      <div id="login-div">
        {this.showRulesModal()}
        <img style={styles.imageback} src={logo} />
        <div style={this.logincon()}>
          <div style={styles.header}>
            <h6>Brought to you by the makers of </h6>
            <h2>FreeN'Fun BarBingo</h2>
            <h6>1.0.0</h6>
          </div>
          <div style={styles.form}>
            <TextField
              className="input"
              value={this.state.email}
              onChange={this.handleEmailChange}
              type="text"
              id="standard-basic"
              label="Email"
            />
            <TextField
              className="input"
              value={this.state.password}
              onChange={this.handlePassChange}
              type="password"
              id="standard-basic"
              label="Password"
            />
            <Button
              className="btnwhite"
              onClick={this.login}
              variant="contained"
            >
              Login
            </Button>
            
            <span style={styles.errorTextStyle}>{this.state.error}</span>
            <div className="links">
              <p onClick={this.props.authswitch.bind(this,"2")}> Don't have an account? Register</p>
              <p  onClick={this.props.authswitch.bind(this,"1")} >Forgot Password?</p>

              <div>
               
                <a target="_blank" href="https://fnfbargame.net/schedule">Schedule</a> | <p onClick={this.showrules} >Rules</p>
              </div>
            </div>
          </div>
          <div></div>
          {/* <button onClick={this.getCard}>Login</button> */}
        </div>
      </div>
    );
  }
}
const styles = {
  imageback: {
    // width:50
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: 0,
  },
  logincon: {
    position: "absolute",
    zIndex: 10000,
  },
  header: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: 0,
    position: "absolute",
    top: "35%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    position: "absolute",
    bottom: 50,
  },
  rule: {
    paddingBottom: 10,
  },
  errorTextStyle: {
    alignSelf: "center",

    fontSize: 18,
    color: "white",
    // marginTop:10,
    marginBottom: 10,
  },
};
export default Login;
