import React, { Component, Fragment } from "react";
import { Const } from "./Const";
// import { Text, View } from "native-base";



// import { Video } from "expo-av";

class PatternCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url:this.props.url
    
    };
  }

  componentWillUnmount() {}
  componentDidMount() {}

  render() {
    return (
      <div style={styles.container}>
      <img src={this.props.url } style={styles.image}/>
       
    
    </div>
    );
  }
}

const styles = {
  container: {
    display:'flex',
    justifyContent:'center',
    flexDirection: "column",
    position:'absolute',
    zIndex:1000,
    width:Const.SCREEN_WIDTH,
    backgroundColor:'black',
    height:Const.SCREEN_HEIGHT-64
  },
  image: {
    width:'100%'
  },
 
};

export { PatternCon };
